import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../constants/Color';
import { Home } from "@styled-icons/fa-solid/Home";

const LinkButton = styled.a<{}>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  color: ${AppColor.DISABLED};

  &:hover{
    color: ${AppColor.PRIMARY};
  }
`;

const Icon = styled(Home)`
  width: 100%;
  height: 100%;
`;

interface Property{
  siteUrl: string;
}


export class MysiteButtonSmall extends PureComponent<Property>{
  
  public render(): JSX.Element{
    return (
      <LinkButton href={this.props.siteUrl}>
        <Icon />
      </LinkButton>
    );
  }
}