import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';

interface Props{
  text: string;
}

const H3 = styled.h3<{}>`
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
`;

export class MemberCardTitle extends PureComponent<Props>{

  public render() : JSX.Element{
    return(
      <H3>
        {this.props.text}
      </H3>
    );
  }
}