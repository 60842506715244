import * as React from 'react';
import { PureComponent } from "react";
import styled from 'styled-components';
import { IMember } from '../../constants/Member';
import { AvaterIcon } from '../atoms/images/AvaterIcon';
import { MemberCardTitle } from '../atoms/headings/MemberCardTitle';
import { TagBlock } from './TagBlock';
import { Biography } from '../atoms/paragraphs/Biography';
import { MysiteButtonSmall } from '../atoms/links/MysiteButtonSmall';
import { TwitterButtonSmall } from '../atoms/links/TwitterButtonSmall';

const Card = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 64px 1fr;
  grid-gap: 32px;
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
`;

const Information = styled.div`
  width: 100%;
`;

const Domains = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const NameLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

const LinkItem = styled.div`
  margin-left: 10px;

  width: 1.2rem;
  height: 1.2rem;
`;

interface Property{
  member: IMember;
}

export class MemberCard extends PureComponent<Property>{

  private renderSiteButton(): JSX.Element | null{
    if(!this.props.member.site) return null;
    else{
      return (
        <LinkItem>
          <MysiteButtonSmall siteUrl={this.props.member.site} />
        </LinkItem>
      );
    }
  }

  private renderTwitterButton(): JSX.Element | null{
    if(!this.props.member.twitter) return null;
    else{
      return(
        <LinkItem>
          <TwitterButtonSmall accountName={this.props.member.twitter} />
        </LinkItem>
      );
    }
  }

  public render(): JSX.Element{
    return(
      <Card>
        <IconContainer>
          <AvaterIcon filename={this.props.member.avater} />
        </IconContainer>
        <Information>
          <NameLinkContainer>
            <MemberCardTitle text={this.props.member.display} />
            {this.renderSiteButton()}
            {this.renderTwitterButton()}
          </NameLinkContainer>
          <Domains>
            <TagBlock tags={this.props.member.domains} />
          </Domains>
          <Biography bio={this.props.member.bio} />
        </Information>
      </Card>
    );
  }
}