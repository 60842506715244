import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';

const Text = styled.p`
  margin-bottom: 0.2rem;
`;

const Line = styled.span`
  line-height: 1;
`;

interface Property{
  bio: string;
}

export class Biography extends PureComponent<Property>{

  public renderLine(line: string, isLastLine: boolean): JSX.Element{
    if(isLastLine){
      return <Line>{line}</Line>;
    }
    else{
      return <Line>{line}<br /></Line>;
    }
  }

  public render(): JSX.Element{
    const lines = this.props.bio.split("\n");
    return(
      <Text>
        {lines.map((l, i) => this.renderLine(l, i === lines.length - 1))}
        {this.props.children}
      </Text>
    )
  }
}