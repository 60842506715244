import * as React from 'react';
import { PureComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { AppColor } from '../../../constants/Color';
import Img from "gatsby-image/withIEPolyfill"
import styled from 'styled-components';

const Icon = styled(Img)`
  width: 100%;
  height: 100%;

  border-style: solid;
  border-radius: 100%;
  border-width: 1px;
  border-color: ${AppColor.PRIMARY};
`;

interface Property{
  filename: string;
}

export class AvaterIcon extends PureComponent<Property>{
  
  public render(): JSX.Element | null{
    const avaterEdges = useStaticQuery(graphql`
      query {
        images: allFile(filter: {dir: {regex: "/images/avaters/"}}) {
          edges {
            node {
              id
              childImageSharp {
                sizes {
                  ...GatsbyImageSharpSizes
                }
              }
              relativePath
            }
          }
        }
      }
    `);

    const image = avaterEdges.images.edges.find(n => n.node.relativePath.includes(this.props.filename));

    if(!image) return null;
      
    return(
      <Icon
        alt={this.props.filename}
        sizes={image.node.childImageSharp.sizes}
        objectFit="cover"
      />
    );
  }
}