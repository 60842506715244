import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { Tag } from '../atoms/texts/Tag';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
`;

const TagBox = styled.span`
  margin-right: 8px;
`;

interface Property{
  tags: string[];
}

export class TagBlock extends PureComponent<Property>{

  private renderTag(tag: string): JSX.Element{
    return(
      <TagBox>
        <Tag tag={tag} />
      </TagBox>
    );
  }

  public render(): JSX.Element | null{
    return(
      <Container>
        {this.props.tags.map(t => this.renderTag(t))}
      </Container>
    );
     
  }
}