import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor, FontColor } from '../../../constants/Color';

interface Props{
  tag: string;
}

const TagBlock = styled.span<{}>`
  box-sizing: border-box;

  background-color: ${AppColor.SECONDARY};
  color: ${FontColor.SECONDARY};
  font-size: 0.8rem;
  border-radius: 8px;

  padding: 4px 16px;
`;

export class Tag extends PureComponent<Props>{

  public render() : JSX.Element{
    return(
      <TagBlock>
        {this.props.tag}
      </TagBlock>
    );
  }
}