import * as React from 'react';
import { Component } from "react";
import styled from 'styled-components';
import Layout from "../components/layout";
import { SEO } from '../components/seo';
import { ArticleTitle } from '../components/atoms/headings/ArticleTitle';
import { HorizontalSeparator } from '../components/atoms/separators/HorizontalSeparator';
import { IMember, getMembers } from '../constants/Member';
import { MemberCard } from '../components/molecules/MemberCard';

const CardContainer = styled.div`
  margin-bottom: 4rem;
`;

export default class members extends Component<any>{

  private get regularMembers(): IMember[]{
    return Object.values(getMembers()).filter(m => m.isRegular);
  }

  private renderCard(member: IMember): JSX.Element{
    return(
      <CardContainer>
        <MemberCard member={member} />
      </CardContainer>
    );
  }
  
  public render(): JSX.Element{

    return(
      <Layout>
        <SEO 
          isArticle={false}
          title={"Members"}
          cover={undefined}
        />
        <ArticleTitle text="Members"/>
        <HorizontalSeparator />
        {this.regularMembers.map(m =>this.renderCard(m))}
      </Layout>
    );
  }
}